<template>
  <div class="recruit">
    <div class="top">
      <img
        src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/top.jpg"
        alt=""
      />
    </div>
    <div class="main" v-if="!isSuccess">
      <div class="shuru">
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item label="企业名称" prop="gNane">
            <el-input v-model="form.gNane" placeholder="企业名称" />
          </el-form-item>
          <el-form-item label="人员办公地点" prop="pOfficeLocation">
            <el-input
              v-model="form.pOfficeLocation"
              placeholder="例：北京/苏州…"
            />
          </el-form-item>
          <el-form-item label="企业联系人" prop="gContactsName">
            <el-input
              v-model="form.gContactsName"
              placeholder="请输入企业联系人"
            />
          </el-form-item>
          <el-form-item label="企业联系人联系方式" prop="gContactsInfo">
            <el-input
              v-model="form.gContactsInfo"
              placeholder="请输入请您填写手机号/微信号"
            />
          </el-form-item>
          <el-form-item label="需求人数" prop="dNum">
            <el-input v-model="form.dNum" placeholder="请输入需求人数" />
          </el-form-item>
          <el-form-item label="需求国家/地区" prop="dArea">
            <el-input v-model="form.dArea" placeholder="请输入需求国家/地区" />
          </el-form-item>
          <el-form-item label="需求性别" prop="dGender">
            <br />
            <!-- <el-input v-model="form.dGender" placeholder="请输入需求性别" /> -->
            <div class="nannv">
              <el-radio v-model="form.dGender" label="1">男</el-radio>
              <el-radio v-model="form.dGender" label="2">女</el-radio>
              <el-radio v-model="form.dGender" label="3">不限</el-radio>
            </div>
          </el-form-item>
          <el-form-item label="需求肤色" prop="dColourSkin">
            <el-input v-model="form.dColourSkin" placeholder="请输入需求肤色" />
          </el-form-item>
          <el-form-item label="需求年龄区间">
            <br />
            <div style="nannv">
              <el-input v-model="minAge" placeholder="最小值" />
              <div style="height: 12px"></div>
              <el-input v-model="maxAge" placeholder="最大值" />
            </div>
          </el-form-item>
          <el-form-item label="需求资质" prop="dQualifications">
            <el-input
              v-model="form.dQualifications"
              placeholder="例：TESOL/TEFOL…"
            />
          </el-form-item>
          <el-form-item label="需求年限">
            <!-- <el-input v-model="form.dYears" placeholder="例：无/1-2/…" /> -->
            <div style="nannv">
              <el-input v-model="minYear" placeholder="最小值" />
              <div style="height: 12px"></div>
              <el-input v-model="maxYear" placeholder="最大值" />
            </div>
          </el-form-item>
          <el-form-item label="薪资范围/月/人" prop="remark1">
            <el-input v-model="form.remark1" placeholder="最小值" />
          </el-form-item>
          <el-form-item label="" prop="remark2">
            <el-input v-model="form.remark2" placeholder="最大值"
          /></el-form-item>
          <el-form-item label="其他需求" prop="dRemark">
            <el-input
              v-model="form.dRemark"
              type="textarea"
              :rows="3"
              placeholder="例：共需要20人，非洲5人，美国15人，男10人，女10人，身高体重，要求必须有XX证书，有幼儿教学经验…"
            />
          </el-form-item>
        </el-form>

        <div class="submitBtn" @click="toSubmit">提交</div>
      </div>
    </div>

    <div class="chenggong" v-if="isSuccess">
      <span>Success!</span>
    </div>
  </div> 
</template>

<script>
// import { listJob, getJob, delJob, addJob, updateJob } from "@/api/job";
export default {
  name: "recruit",
  components: {},
  data() {
    return {
      isSuccess: false,
      minAge: "",
      maxAge: "",
      minYear: "",
      maxYear: "",
      form: {
        gNane: null,
        pOfficeLocation: null,
        gContactsName: null,
        gContactsInfo: null,
        dNum: null,
        dArea: null,
        dGender: null,
        dColourSkin: null,
        dAgeRange: null,
        dQualifications: null,
        dYears: null,
        dSalary: null,
        dRemark: null,
        remark1: null,
        remark2: null,
      },
      rules: {
        remark1: [
          { required: true, trigger: "blur", message: "请输入您的最低薪资" },
        ],
        remark2: [
          { required: true, trigger: "blur", message: "请输入您的最高薪资" },
        ],
        gNane: [
          { required: true, trigger: "blur", message: "请输入您的企业名称" },
        ],
        pOfficeLocation: [
          {
            required: true,
            trigger: "blur",
            message: "请输入您的人员办公地点",
          },
        ],
        gContactsName: [
          {
            required: true,
            trigger: "blur",
            message: "请输入您的企业联系人",
          },
        ],

        gContactsInfo: [
          {
            required: true,
            trigger: "blur",
            message: "请输入您的企业联系人方式",
          },
        ],

        dNum: [
          {
            required: true,
            trigger: "blur",
            message: "请输入您的需求人数",
          },
        ],
        dArea: [
          {
            required: true,
            trigger: "blur",
            message: "请输入您的需求国家/地区",
          },
        ],
        // dGender: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //     message: "请输入您的需求性别",
        //   },
        // ],
        // dColourSkin: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //     message: "请输入您的需求性别",
        //   },
        // ],
        // dAgeRange: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //     message: "请输入您的需求年龄范围",
        //   },
        // ],
        // dQualifications: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //     message: "请输入您的需求资质（证书）",
        //   },
        // ],
        // dYears: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //     message: "请输入您的需求经验年限",
        //   },
        // ],
        // dSalary: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //     message: "请输入您的预计薪资范围/月/人",
        //   },
        // ],
        // dRemark: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //     message: "请输入您的补充需求",
        //   },
        // ],
      },
    };
  },
  created() {},
  mounted() {
  },
  methods: {
    toSubmit() {
      this.form.dAgeRange = this.minAge + "-" + this.maxAge;
      this.form.dYears = this.minYear + "-" + this.maxYear;
      console.log(
        this.form.remark1,
        this.form.remark2,
        this.form.gNane,
        this.form.pOfficeLocation,
        this.form.gContactsName,
        this.form.gContactsInfo,
        this.form.dNum,
        this.form.dArea
      );
      if (
        this.form.remark1 !== "" &&
        this.form.remark1 !== null &&
        this.form.remark2 !== "" &&
        this.form.remark2 !== null &&
        this.form.gNane !== "" &&
        this.form.gNane !== null &&
        this.form.pOfficeLocation !== "" &&
        this.form.pOfficeLocation !== null &&
        this.form.gContactsName !== "" &&
        this.form.gContactsName !== null &&
        this.form.gContactsInfo !== "" &&
        this.form.gContactsInfo !== null &&
        this.form.dNum !== "" &&
        this.form.dNum !== null &&
        this.form.dArea !== "" &&
        this.form.dArea !== null
      ) {
        this.api.addJob(this.form).then((response) => {
          // this.$modal.msgSuccess("提交成功");
          this.$message({
          message: '提交成功',
          type: 'success'
        });
          this.minAge = "";
          this.maxAge = "";
          this.minYear = "";
          this.maxYear = "";
          this.form.gNane = null;
          this.form.pOfficeLocation = null;
          this.form.gContactsInfo = null;
          this.form.dNum = null;
          this.form.dArea = null;
          this.form.dGender = null;
          this.form.dColourSkin = null;
          this.form.dAgeRange = null;
          this.form.dQualifications = null;
          this.form.dYears = null;
          this.form.dSalary = null;
          this.form.dRemark = null;
          this.form.remark1 = null;
          this.form.remark2 = null;
          this.isSuccess = true;
        });
      } else {
        this.$message.error("请填写必填信息!");
      }
    },
  },
};
</script>
<style scoped>
.recruit {
}
.top,
.top img {
  width: 100vw;
  height: 200px;
  margin-bottom: 10px;
}

.main {
  width: 100vw;
  padding: 10px 0px;
  height: 1500px;
  background-size: 100% 100%;
  margin: 0 auto;
  background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/bj.jpg");
}

.chenggong {
  width: 100vw;
  height: 80vh;
  text-align: center;
  font-size: 20px;
  margin: 20px auto;
  font-weight: bold;
  background: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/bj.jpg")
    no-repeat;
  background-size: 100% 100%;
}

.chenggong span {
  color: white;
  font-size: 35px;
  display: block;
  padding-top: 200px;
}

div /deep/.el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #fff;
  line-height: 40px;
  padding: 0 12px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.shuru {
  width: 280px;
  padding: 0 5px;
  margin: 0 auto;
}

.submitBtn {
  width: 250px;
  height: 40px;
  border-radius: 40px;
  background: rgb(230, 112, 72);
  color: white;
  text-align: center;
  line-height: 40px;
  margin: 0 auto;
  margin-top: 0px;
  font-weight: bold;
}

.nannv {
  display: flex;
  justify-content: space-between;
  width: 280px;
}

div /deep/.el-radio {
  color: white;
}

div /deep/.el-form-item {
  margin-bottom: 18px;
}
</style>
